@import "./Mixins.sass"

.modal
  position: absolute,
  top: 0
  left: 0
  right: 0
  bottom: 0
  width: 100%
  height: 100%
  background: rgba(52, 51, 51, 0.5)
  padding: 100px
  backdrop-filter: blur(10px)
  display: flex,
  flex-direction: row
  justify-content: center
  align-items: flex-start
  &-content
    width: 100%
    max-width: 500px
    padding: 20px
    height: 300px
    background: #fff
    border-radius: 20px
    display: flex,
    flex-direction: column
    justify-content: center
    align-items: center
    gap: 20px
    input[type='password']
      width: 100%
      padding: 12px
      border-radius: 10px
      border: 1px solid #ccc
    button
      color: white
      @include active-control
      padding: 8px 16px
      border: none
      text-transform: uppercase
      font-weight: 500
      border-radius: 10px
      font-size: 16px
      &:hover
        cursor: pointer
        opacity: 0.8
    h3
      color: #222
      margin: 0
      padding: 0
      text-align: center
