@import './Mixins'
ul.events
  margin: 0
  padding: 0
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  width: 100%
  list-style-type: none
  & > li.event
    position: relative
    display: flex
    flex-direction: column
    justify-content: space-between
    padding: 0
    height: auto
    margin: 10px 0px
    width: 32%
    border-radius: 2px
    cursor: pointer
    background-color: #3b3b3b
    font-size: 15px
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px
    .event-body
      img
        width: 100%
    .event-title
      @include title
      padding: 5px 10px
    .event-description
      font-size: 13px
      padding: 5px 10px
      overflow: auto
      color: rgba(255, 255, 255, 0.8)
      & h1,h2,h3,h4,h5,h6
        margin: 3px 0
    .event-footer
      display: flex
      flex-direction: row
      justify-content: space-between
      width: 100%
      font-size: 13px
      padding: 5px 10px
      &_duedate
        color: rgba(255, 255, 255, 0.8)
      &_id
        color: yellow
