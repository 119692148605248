header.header
  display: flex
  position: sticky
  top: 0
  height: 100px
  background-color: #398D73
  & > .header-center
    min-height: 100px
    flex-grow: 1
    text-align: center
    display: flex
    align-items: center
    justify-content: center
    & > .header-title
      h1, h2, h3, h4, h5, h6
        margin: 0